(function(win, $) {
  'use strict';
  var fp = (win.fp = typeof win.fp !== 'undefined' ? win.fp : {}),
    template = (fp.template = typeof fp.template !== 'undefined' ? fp.template : {});

  fp.template = (function() {
    var getCalloutSize = function() {
      var pixelDensity = window.devicePixelRatio || 1;
      var width = window.innerWidth * pixelDensity;

        if (width >= 1600) {
            return 'xlarge';
        }
        if (width >= 1200) {
            return 'large';
        }
        if (width >= 800) {
            return 'medium';
        }
        return 'small';
    };

    return {
      setCalloutBgImage: function(calloutSelector, imgInfo) {
        var screenSize = getCalloutSize();
        var bgImg = imgInfo.filter(function(img) {
          return img.assetSize === screenSize;
        })[0].imagePath;

        var el = document.querySelector(calloutSelector);
        el.style.backgroundImage = "url('" + bgImg + "')";
      }
    };
  })();
})(window, $);